import React from 'react';
import {Helmet} from "react-helmet";
// import { Router, Route, Switch } from "react-router";
import { BrowserRouter as Router, Route, Switch, Link }  from "react-router-dom";
import {createBrowserHistory} from 'history'

import logo from './lig-0-bronze-300.png';
import listUnit_001 from './img/decor/listUnit_001.svg';

import Filter001 from './components/filter.js';
import EventEdit from './components/events-edit.js';
import EventFrame from './components/events-frame.js';
import Map001 from './components/map.js';
import FormReg from './components/form-reg.js';
import FormLogin from './components/form-login.js';
import UserLK from './components/lk.js';
import Test from './components/newcomp003.js';

import {ContextApp} from './context.js'

// import EventPlusBtn from './components/base-elements';
import './ifstrap.css';
import './desctop.css';
import './App.css';

class App extends React.Component {

  constructor(props){
    super(props);
    this.appLocationState = this.appLocationState.bind(this);
    this.state = {
      page: 'map'
    }
  }

  appLocationState(val, param, location){
    this.setState({page: val, location: location, param: param});
    console.log(val+', '+location+', '+param)
  }

  componentDidMount(){
    this.showState()
  }
  showState(){
    // const [todos, setState] = React.useState([{1: 1234, 2:4321}, {3: 1234, 4:4321},])
    const REACT_VERSION = React.version;
    console.log(REACT_VERSION)
    // console.log(this.state)
  }

  // рендер_Фильтра
  appLocationFilter(){
    return(
      <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Title</title>
            <link rel="canonical" href="https://ifsmart.ru" />
        </Helmet>
        <header>
        </header>
        <Filter001 appLocationState={this.appLocationState.bind(this)} />
      </div>
    )
  }

  // рендер_Редактора_Эвентов
  appLocationEventEdit(){
    return(
      <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Title</title>
            <link rel="canonical" href="https://ifsmart.ru" />
        </Helmet>
        <header>
        </header>
        <EventEdit 
          appLocationState={this.appLocationState.bind(this)}
          token={this.state.param}
          location={this.state.location}
        />
      </div>
    )
  }

  // рендер_Фрейма_Эвентов
  appLocationEventFrame(){
    return(
      <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Title</title>
            <link rel="canonical" href="https://ifsmart.ru" />
        </Helmet>
        <header>
        </header>
        <EventFrame
          appLocationState={this.appLocationState} 
          token={this.state.param}
        />
      </div>
    )
  }

  // рендер_Карта
  appLocationMap(){
    return(
      <div className="App">
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Title</title>
            <link rel="canonical" href="https://ifsmart.ru" />
        </Helmet>
        <header>
        </header>
        <Map001 
          appLocationState={this.appLocationState}
          location={this.state.location}
          param={this.state.param}
        />
      </div>
    )
  }

  // рендер_формы_регистрации
  appLocationFormReg(){
    return(
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Title</title>
          <link rel="canonical" href="https://ligix.ru" />
        </Helmet>
        <header>
        </header>
        <FormReg 
          appLocationState={this.appLocationState.bind(this)}
        />
      </div>
    )
  }

  // рендер_формы_авторизации
  appLocationFormLogin(){
    return(
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Title</title>
          <link rel="canonical" href="https://ligix.ru" />
        </Helmet>
        <header>
        </header>
        <FormLogin
          appLocationState={this.appLocationState.bind(this)}
        />
      </div>
    )
  }

    // рендер_личного_кабинета
  appLocationLK(){
    return(
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Title</title>
          <link rel="canonical" href="https://ligix.ru" />
        </Helmet>
        <header>
        </header>
        <UserLK
          appLocationState={this.appLocationState.bind(this)}
        />
      </div>
    )
  }

  // рендер_тестовой_страницы
  appLocationTest(){
    return(
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Title</title>
          <link rel="canonical" href="https://ligix.ru" />
        </Helmet>
        <header>
        </header>
        <Test/>
      </div>
    )
  }

  render(){
    let mainRender;

    if( this.state.page == 'filter' )
      mainRender = this.appLocationFilter();

    else if( this.state.page == 'map' )
      mainRender = this.appLocationMap();

    else if( this.state.page == 'newEvent' || this.state.page == 'eventEdit' )
      mainRender = this.appLocationEventEdit();

    else if( this.state.page == 'eventFrame' )
      mainRender = this.appLocationEventFrame();

    else if( this.state.page == 'reg' )
      mainRender = this.appLocationFormReg();

    else if( this.state.page == 'login' )
      mainRender = this.appLocationFormLogin();

    else if( this.state.page == 'lk' )
      mainRender = this.appLocationLK();

    else if( this.state.page == 'test' )
      mainRender = this.appLocationTest();

    if (mainRender){
      return(
        <ContextApp.Provider value={{location: this.state.location, appLocationState: this.appLocationState}}>
          <div>
            {mainRender}
          </div>
        </ContextApp.Provider>
      )
    }
    else{
      return(
        <Router>
          <div className="App">
            <Switch>
              <Route exact path="/" component={Map001} />
              <Route exact path="/lk" component={UserLK} />
            </Switch>
          </div>
        </Router>
      )
    }
  }
}

export default App;
