import React from 'react';
import Cookies from 'js-cookie';

import MainLogo_001 from '../img/decor/logo01.svg';
import User_001 from '../img/decor/user01.svg';
import User_002 from '../img/decor/user02.svg';
import User_003 from '../img/decor/user03.svg';

/* простая Шапка */
export class BaseHead001 extends React.Component {

	constructor(props){
		super(props);
		this.appNav = this.appNav.bind(this);
		this.state = {
			token: Date.now(),
			userLogin: null
		}

		// this.timer() //подключение таймера к компоненту
	}

	appNav(val){
		this.props.appNav(val);
	}

	componentDidMount(){
		let userLogin = Cookies.get('loggedUser');
		console.log(userLogin);
		if(userLogin){
			this.setState({
				userLogin: userLogin 
			});
		}
	}

	render(){
		return(
			<div className="baseHeadWr">
				<div className="divFlexBetween">
					<div className="userPictWr">
						<img src={MainLogo_001} alt="logo" onClick={this.appNav.bind(this, 'map')}/>
					</div>
					<div className="userPictWr">
						{this.state.userLogin ? (<img src={User_003} alt="user" onClick={this.appNav.bind(this, 'lk')}/>) : (<img src={User_001} alt="user" onClick={this.appNav.bind(this, 'login')}/>)}
					</div>
				</div>
			</div>
		)
	}
}

export default BaseHead001;