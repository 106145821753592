import React from 'react';

import User_001 from '../img/decor/user_001.svg';

/* кнопка Добавить Эвент */
export class EventPlusBtn extends React.Component {
	render(){
		return(
			<div className="plusBtn">
				<span>{this.props.btnCont}</span>
			</div>
		)
	}
}

/* кнопка_с_базовым_экшном */
export class BaseActionBtn extends React.Component {

	appNav(val){
		if(this.props.appNav())
			this.props.appNav(val);
		// console.log(val)
	}

	render(){
		return(
			<div className="baseActionBtnWr">
				<div className="baseActionBtn" onClick={this.appNav.bind(this, 'newEvent')} >
					<span>{this.props.btnName}</span>
				</div>
			</div>
		)
	}
}
