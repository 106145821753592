import React, { useContext } from 'react'
import {ContextApp} from '../context.js'

export default function TodoList(){
	const someConst = useContext(ContextApp)
	function consoleEnter(){
		console.log(someConst.someValue)
	}
	return (
		<ul>
			<li onClick={()=>consoleEnter()}>
				1
			</li>
			<li>
				2
			</li>
			<li>
				3
			</li>
		</ul>
	)
}