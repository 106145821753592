import React from 'react';
import BaseHead001 from './base-head001';
import {BaseActionBtn} from './base-elements';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Cookies from 'js-cookie';

class FormLogin extends React.Component {
	
	constructor(props) {
		super(props);
		this.handleForm = this.handleForm.bind(this);
		this.appNav = this.appNav.bind(this);
		this.state = {
			token: Date.now(),
			sysMessage: null
		}
	}

	appNav(val, param){
		this.props.appLocationState(val, param);
	}

	handleForm(event){
		let set = event.target.name;
		let val = event.target.value;
		this.setState({ [set]: val 	})
	}

	sendData(){
		const req1 = new URLSearchParams();
		req1.append('userLogin', 1);
		let data = this.state;
		for(let prop in data){
			req1.append(prop, data[prop]);
		}
	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((rows) => {
	    	
	    	if(rows.userlogin){
	    		// console.log('CookieLogin');
	    		Cookies.set('loggedUser', rows.userToken);
	    		this.appNav('map')
	    	}else{
	    		let resp = (<div className="sysMessage">{rows.resp}</div>);
	    		this.setState({ sysMessage: resp });
	    		setTimeout(() => {
				  this.setState({ sysMessage: null })
				}, 3 * 1000)
	    	}
	    })
	    // .then(rows => this.setState({ sysMessage: null }))
	    // .then(rows => console.log(rows))
	}

	render(){
		return(
			<div className="baseWrapper">
				<BaseHead001 appNav={this.appNav.bind(this)} pageName="РЕГИСТРАЦИЯ" />

				<div className="baseContWr">
					<div className="baseFormWr0">
						<div className="baseFormWr borderHead01">
							<input type="hidden" name="token" defaultValue={this.state.token} />
							<div className="baseFormInpWr">
								<p>Логин</p>
								<input 
									type="text" 
									name="login"
									value={this.state.login} 
									onChange={this.handleForm}
								/>
							</div>

							<div className="baseFormInpWr">
								<p>
									Пароль
								</p>
								<input 
									type="password" 
									name="password"
									value={this.state.password} 
									onChange={this.handleForm}
								/>
							</div>	

							<div className="formActionBtnWr mt-5">
								<div className="baseActionBtn" onClick={this.sendData.bind(this)}>
									<span>ВОЙТИ</span>
								</div>
							</div>
							<ReactCSSTransitionGroup
								className="sysMessage"
								transitionName="example"
								transitionAppear={true}
								transitionAppearTimeout={500}
								transitionEnter={true}
								transitionLeave={true}
							>
								{this.state.sysMessage}
							</ReactCSSTransitionGroup>

						</div>

						<div className="baseFormWr">
							<div className="formActionBtnWr">
								<div className="baseActionBtn" onClick={this.appNav.bind(this, 'reg')}>
									<span>РЕГИСТРАЦИЯ</span>
								</div>
							</div>
						</div>
					</div>
				</div>
					
	        </div>
		)
	}

}

export default FormLogin;