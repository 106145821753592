import React from 'react';
import BaseHead001 from './base-head001';
import {BaseActionBtn} from './base-elements';

import User_001 from '../img/decor/user01.png';
import Close01 from '../img/decor/close01.png';
import Mail01 from '../img/decor/mail01.png';
import Phone01 from '../img/decor/phone01.png';
import Geo_002 from '../img/decor/geo_002.svg';
import Calendar_002 from '../img/decor/calendar_002.svg';
import Clock_002 from '../img/decor/clock_002.svg';
import ArrowLeft from '../img/decor/arrow_y_001_l.svg';
import ArrowRight from '../img/decor/arrow_y_001_r.svg';

class EventFrame extends React.Component {
	constructor(props){
		super(props);
		this.appNav = this.appNav.bind(this)
	}
	state = {
		eventData: null
	}
	appNav(val, param){
		this.props.appLocationState(val, param);
	}
	componentDidMount(){
		this.getEventData();
	}

	getEventData(){
		const token = this.props.token;
		// const token = 1597922540570;

		const req1 = new URLSearchParams();
		req1.append('getEventData', 1);
		req1.append('token', token);

	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((eventData) => {
	    	for(let param in eventData){
	    		this.setState({
					[param]: eventData[param]
	    		})
	    	}
	    	// let eventDate = new Date(Number(eventData.start_date));
	    	// eventDate = eventDate.toLocaleString('ru',{
	    	// 		day:   '2-digit',
				  //   month: '2-digit',
				  //   year:  'numeric'
	    	// 	})
	    	// this.setState({start_date: eventDate})
	    })
	    // .then(rows => console.log(rows))
	}

	render(){
		return(
			<div className="baseWrapper">
				<BaseHead001 appNav={this.appNav.bind(this)} pageName="описание эвента" />

				<div className="baseContWr">
					<div className="evFame_mainWr">
						<div className="closeWr">
							<img 
								src={Close01} 
								alt="close"
								onClick={() => this.appNav('map', this.state.token)}
							/>
						</div>
						<div className="evFame_mainWrSub">
							
							<div className="evFame_evHead">
								{this.state.point}
							</div>
							<div className="evFame_evDisc">
									{this.state.disc}
							</div>
							<div className="evFame_mainWr3">
								<div className="evFame_mainWr3">
									<img src={User_001} alt="user"/>
									<span className="evFame_ContName">Иванов З</span>
								</div>
								
								<div className="evFame_ContWr0">
									<div className="evFame_ContWr1">
										<img src={Phone01} alt="user"/>
										<span>{this.state.phone}</span>
									</div>
									<div className="evFame_ContWr1">
										<img src={Mail01} alt="user"/>
										<span>{this.state.email}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
	        </div>
		)
	}
}

export default EventFrame;