import React from 'react';
import {EventPlusBtn} from './base-elements';

import Gear_001 from '../img/decor/gear_001.svg';
import Magmifier_001 from '../img/decor/magmifier_001.svg';
import User_001 from '../img/decor/user_001.svg';

class Filter001 extends React.Component {

	constructor(props){
		super(props);
		this.getEventsList();
		this.appNav = this.appNav.bind(this);
	}

	state = {
		eventsList: null
	}

	appNav(val, param, location){
		this.props.appLocationState(val, param, location);
	}

	getEventsList(){
		const req1 = new URLSearchParams();
		req1.append('getEventsList', 1);

	    fetch('https://server.ligix.ru/teleport.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((rows) => {
	    	let object = Object.assign(this.state);
			object['eventsList'] = rows;
		    this.setState({ object });

		    // object.eventsList.map( (item, i) => { console.log(item) } )
	    })
	    // .then(rows => console.log(rows))
	}
	eventsList(){
		if(this.state.eventsList){
			return(
				this.state.eventsList.map( (item, i) => {
					return(
						<div className="listUnit001" key={i} onClick={() => this.appNav('eventFrame', item.token)}>
				            <div className="listUnitName">
				              {item.location}
				            </div>
				            <div className="listUnitCount">
				              <span>10</span>
				            </div>
						</div>
					)
				})
			)
		}
		else{
			return(<div>YT</div>)
		}
	}

	checkState(){
		this.state.map( (item, i) => { console.log(item) } )
	}

	render(){
		return(
			<div className="filterWrapper">

				<div className="filterHeadWr">
					<div className="divFlexBetween" >
						<div className="filterLocation">
							Ульяновск
						</div>
						<div className="userPictWr">
							<div className="userPictFrame">
								<img src={User_001} alt="user"/>
							</div>
						</div>
					</div>
					<div className="filterHeadSearchWr">
						<div className="filterHeadInpWr">
							<input className="filterHeadInp" type="text"/>
							<img src={Magmifier_001} alt="search"/>
						</div>
						<img src={Gear_001} alt="gear"/>
					</div>
				</div>
				
				<div className="filterMapBtnWr" onClick={() => this.appNav('map', 'all', 'Ульяновск')}>
					Показать на карте
				</div>

				<div className="filterListWrapper">
					<div className="listUnit001" onClick={this.appNav.bind(this, 'eventFrame')}>
			            <div className="listUnitName">
			              Настольный тениc
			            </div>
			            <div className="listUnitCount">
			              <span>10</span>
			            </div>
					</div>

					<div className="listUnit001" onClick={this.appNav.bind(this, 'eventFrame')}>
						<div className="listUnitName">
						  Большой тенис
						</div>
						<div className="listUnitCount0">
						</div>
					</div>

					<div className="listUnit001" onClick={this.appNav.bind(this, 'eventFrame')}>
						<div className="listUnitName">
						  Лазертаг
						</div>
						<div className="listUnitCount0">
						</div>
					</div>
					{this.eventsList()}

				</div>
				
				<EventPlusBtn appNav={this.appNav.bind(this)} />
	        </div>
		)
	}
}

export default Filter001;