import React, { useContext } from 'react';
import Cookies from 'js-cookie';
import Context from '../context.js';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import BaseHead001 from './base-head001';
import {BaseActionBtn} from './base-elements';
import ArrowLeft from '../img/decor/arrow_y_001_l.svg';
import ArrowRight from '../img/decor/arrow_y_001_r.svg';
import MarkerPets from '../img/decor/marker03.png';
import AddresMarker from '../img/geo/markerPoligon1.svg';
import MarkerRedDog from '../img/geo/markerDogRed01.svg';
import MarkerGreenDog from '../img/geo/markerDogGreen01.svg';
import MarkerRedCat from '../img/geo/markerCatRed01.svg';
import MarkerGreenCat from '../img/geo/markerCatGreen01.svg';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import * as ru from 'date-fns/locale/ru';

class EventEdit extends React.Component {

	constructor(props){
		let myDate = new Date();

		super(props);
		// this.handleRadio = this.handleRadio.bind(this);
		// this.handleCheckbox = this.handleCheckbox.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleForm = this.handleForm.bind(this);
		this.handleStartTime = this.handleStartTime.bind(this);
		this.dateInp = React.createRef();
		this.appNav = this.appNav.bind(this);
		this.state = {
			token: Date.now(),
			category: 'Пропавшие собаки'
		}

		// this.timer() //подключение таймера к компоненту
	}
	appNav(val, param, location){
		this.props.appLocationState(val, param, location);
	}
	componentDidMount(){
		if(this.props.token)
			this.getEventData();
		if(this.props.location){
			this.setState({ location: this.props.location });
			this.findLocaton(this.props.location)
		}
		if (Cookies.get('loggedUser')) {
			let userToken = Cookies.get('loggedUser');
			this.getUserData(userToken)
		}
	}

	getEventData(){
		const req1 = new URLSearchParams();
		req1.append('getEventData', 1);
		req1.append('token', this.props.token);

	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((eventData) => {
	    	for(let param in eventData){
	    		console.log(param)
	    		let val = eventData[param];
	    		this.setState({
	    			[param]: val
	    		})
	    	}
	    })
	    // .then(rows => console.log(rows))
	}

	getUserData(userToken){
		const req1 = new URLSearchParams();
		req1.append('getUserData', 1);
		req1.append('userToken', userToken);

	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((userData) => {
	    	this.setState({
    			userId: userData.id,
    			login: userData.login,
    			author: userData.author,
    			phone: userData.phone,
    			email: userData.email,
	    	})
	    })
	}

	handleForm(event){
		let set = event.target.name;
		let val = event.target.value;
		this.setState({ [set]: val 	})
	}
	handleGeo(event){
		let set = event.target.name;
		let val = event.target.value;
		this.setState({ [set]: val 	});

		let request = 'Ульяновск' + val;
		fetch('https://geocode-maps.yandex.ru/1.x/?apikey=39760cf0-262c-484c-b9ba-6b8a31e8b251&format=json&geocode=' + request)
		.then(r => r.json())
		.then((result) => {
			if(result['response'] && result['response']['GeoObjectCollection']['featureMember'][0]){
				let pos = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['Point']['pos'] || 'не найдено';
				let description = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['description'] || 'не найдено';
				let name = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['name'] || 'не найдено';
				// console.log(pos);
				// console.log(description);
				// console.log(name);
				console.log(result);
				
				pos = pos.split(' ');
				pos = pos[0]+', '+pos[1];
				this.setState({ 
					posApi: pos
				});
			}else{
				console.log('не найдено');
			}
		})
	}

	findLocaton(val){
		// let request = 'Ульяновск' + val;
		let request = val;
		fetch('https://geocode-maps.yandex.ru/1.x/?apikey=39760cf0-262c-484c-b9ba-6b8a31e8b251&format=json&geocode=' + request)
		.then(r => r.json())
		.then((result) => {
			// console.log(result);
			if(result['response'] && result['response']['GeoObjectCollection']['featureMember'][0]){
				let pos = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['Point']['pos'] || 'не найдено';
				let description = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['description'] || 'не найдено';
				let name = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['name'] || null;
				let addresfull = name + ', ' + description;
				// console.log(pos);
				// console.log(description);
				// console.log(name);
				console.log(addresfull);
				
				pos = pos.split(' ');
				pos = pos[1]+', '+pos[0];
				this.setState({ addres: addresfull, pos: pos });
			}else{
				console.log('не найдено');
			}
		})
	}

	handleStartTime(event){
		let stateStartDate = this.state.startDate;

		let inpVal = event.target.value.split(':');
		let startHour = Math.round(inpVal['0']) || 0;
		let startMin = Math.round(inpVal['1']) || 0;

		stateStartDate.setHours(startHour,startMin,0);

		this.setState({startTime: event.target.value})
		this.setState({startDate: stateStartDate})
	}
	handleSelect(event){
		this.setState({selectedValue: event.target.value})
	}
	arrowHendler(polarity){
		if(polarity == 'plus'){
			let setQuant = this.state.players_quant +1;
			this.setState({players_quant: setQuant});
		}
		if(polarity == 'minus' && this.state.players_quant > 1){
			let setQuant = this.state.players_quant -1;
			this.setState({players_quant: setQuant});
		}
	}
	sendData(){
		const req1 = new URLSearchParams();
		req1.append('saveEventData', 1);
		let data = this.state;
		for(let prop in data){
			req1.append(prop, data[prop]);
		}
	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then(rows => { 
	    	let resp = (<div className="pupSysMessage">{rows.resp}</div>);
	    	this.setState({ sysMessage: resp });
	    	setTimeout(() => {
			  this.setState({ sysMessage: null })
			}, 3 * 1000)
	    })
	    // .then(rows => console.log(rows))
	}
	handleDate = date => {
		let hh = date.getHours();
		let ii = date.getMinutes();
		if(ii == 0) ii = '00';
		let custTime = hh + ':' + ii;

		let start_date = date.valueOf();
		this.setState({
			startDate: date,
			start_date: start_date,
			start_time: custTime
		});
	};

	render(){
		let previewMarker;
		if(this.state.category == 'Пропавшие собаки')
			previewMarker = MarkerRedDog;
		if(this.state.category == 'Найденные собаки')
			previewMarker = MarkerGreenDog;
		if(this.state.category == 'Пропавшие кошки')
			previewMarker = MarkerRedCat;
		if(this.state.category == 'Найденные кошки')
			previewMarker = MarkerGreenCat;
		return(
			// <Context.Provider>
				<div className="baseWrapper">
					<BaseHead001 appNav={this.appNav.bind(this)} pageName="создать игру" />

					<div className="baseContWr">
						<div className="newEventFormWr borderHead01">
							<input type="text" name="token" value={this.state.token} />

							<div className="evFormAddresMarkerWr">
								<input 
									type="text" 
									name="addres"
									placeholder="Адрес"
									value={this.state.addres} 
									onChange={this.handleGeo.bind(this)}
								/>
								<img className="evFormAddresMarker" src={AddresMarker} alt="geo" onClick={() => this.appNav('map', 'setAddres', this.state.pos)} />
							</div>

							<select
								name="category"
								value={this.state.category}
								onChange={this.handleForm}
							>
								<optgroup label="Пропавшие животные">
									<option>Пропавшие собаки</option>
									<option>Пропавшие кошки</option>
								</optgroup> 
								<optgroup label="Найденные животные">
									<option>Найденные собаки</option>
									<option>Найденные кошки</option>
								</optgroup>
								<option className="formOptB">Заявка на отлов</option>
							</select>
							<input 
								type="text" 
								name="point"
								placeholder="Название метки"
								value={this.state.point} 
								onChange={this.handleForm}
							/>
							
							<textarea
								name="disc"
								placeholder="Описание"
								value={this.state.disc} 
								onChange={this.handleForm}
							></textarea>
						</div>
						<div className="newEventFormWr">
							<input 
								type="text"
								name="author"
								placeholder="ФИО"
								value={this.state.author} 
								onChange={this.handleForm}
							/>
							<div className="impWr2">
								<input 
									type="text"
									name="phone"
									placeholder="+7(000)000 00-00"
									value={this.state.phone} 
									onChange={this.handleForm}
								/>
								<input 
									type="text"
									name="email"
									placeholder="name@email.ru"
									value={this.state.email}
									onChange={this.handleForm}
								/>
							</div>
							{/*<BaseActionBtn appNav={this.sendData.bind(this)} btnName="Добавить" />*/}
							<ReactCSSTransitionGroup
								transitionName="example"
								transitionAppear={true}
								transitionAppearTimeout={500}
								transitionEnter={true}
								transitionLeave={true}
							>
								{this.state.sysMessage}
							</ReactCSSTransitionGroup>

							<div className="actionBtnWr">
								<div className="formMarkerWr">
									<img  
										className="formMarker" 
										src={previewMarker} 
										alt="marker"
										onClick={() => this.appNav('map', 'showMarker', this.state.pos)}
									/>
								</div>
								<div className="baseActionBtn" onClick={this.sendData.bind(this)}>
									<span>Добавить</span>
								</div>
							</div>
						</div>
					</div>
						
		        </div>
	        // </Context.Provider>
		)
	}
}

export default EventEdit;