import React from 'react';
import BaseHead001 from './base-head001';
import {BaseActionBtn} from './base-elements';

// import { TransitionGroup , CSSTransition } from "react-transition-group";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class FormReg extends React.Component {
	
	constructor(props) {
		super(props);
		this.handleForm = this.handleForm.bind(this);
		this.state = {
			token: Date.now(),
			sysMessage: null
		}
	}

	appNav(val, param){
		this.props.appLocationState(val, param);
	}

	handleForm(event){
		let set = event.target.name;
		let val = event.target.value;
		this.setState({ [set]: val 	})
	}

	checkForm(){
		let errors;
		let formError
		if(!this.state.login && !this.state.password1){
			errors = ( <p>Необходимо заполнить все поля</p>);
		}
		else if(!this.state.login){
			errors = ( <p>Не введен <b>Login</b></p>);
		}
		else if(!this.state.password1){
			errors = ( <p>Не введен <b>Пароль</b></p>);
		}
		else if(!this.state.password2){
			errors = ( <p>Не введен <b>Пароль еще раз</b></p>);
		}
		else if(this.state.password1 != this.state.password2){
			errors = ( <p>Пароли не совпадают</p>);
		}
		
		if(errors){
			formError = (<div>{errors}</div>);
			this.setState({
				sysMessage: formError
			});
			setTimeout(() => {
			  this.setState({ sysMessage: null })
			}, 3 * 1000)
		}else{
			this.sendData();
			// let formSuccess = (<div className="formSuccess">Регистрация успешна!</div>);
			// this.setState({
			// 	sysMessage: formSuccess
			// })
		}
	}

	sendData(){
		const req1 = new URLSearchParams();
		req1.append('saveRegData', 1);
		let data = this.state;
		for(let prop in data){
			req1.append(prop, data[prop]);
		}
	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((rows) => {
	    	let success = (<div>{rows.resp}</div>);
	    	this.setState({ sysMessage: success });
	    	setTimeout(() => {
			  this.setState({ sysMessage: null })
			}, 3 * 1000)
	    })
	    // .then(rows => this.setState({ sysMessage: null }))
	    // .then(rows => console.log(rows))
	}

	render(){
		return(
			<div className="baseWrapper">
				<BaseHead001 appNav={this.appNav.bind(this)} pageName="РЕГИСТРАЦИЯ" />

				<div className="baseContWr">
					<div className="baseFormWr0">
						<div className="baseFormWr borderHead01">
							<input type="hidden" name="token" defaultValue={this.state.token} />
							<div className="baseFormInpWr">
								<p>Логин</p>
								<input 
									type="text" 
									name="login"
									value={this.state.login} 
									onChange={this.handleForm}
								/>
							</div>

							<div className="baseFormInpWr">
								<p>
									Пароль
								</p>
								<input 
									type="password" 
									name="password1"
									value={this.state.password1} 
									onChange={this.handleForm}
								/>
							</div>
								
							<div className="baseFormInpWr">
								<p>
									Пароль еще раз
								</p>
								<input 
									type="password" 
									name="password2"
									value={this.state.password2} 
									onChange={this.handleForm}
								/>
							</div>

						</div>
						<div className="baseFormWr">
							<div className="baseFormInpWr">
								<input 
									type="text"
									name="author"
									placeholder="ФИО"
									value={this.state.author} 
									onChange={this.handleForm}
								/>
							</div>
							<div className="impWr2">
								<input 
									type="text"
									name="phone"
									placeholder="+7(000)000 00-00"
									value={this.state.phone} 
									onChange={this.handleForm}
								/>
								<input 
									type="text"
									name="email"
									placeholder="name@email.ru"
									value={this.state.email}
									onChange={this.handleForm}
								/>
							</div>
							<div className="formActionBtnWr mt-5">
								<div className="baseActionBtn" onClick={this.checkForm.bind(this)}>
									<span>ОТПРАВИТЬ</span>
								</div>
							</div>
							<ReactCSSTransitionGroup
								transitionName="example"
								transitionAppear={true}
								transitionAppearTimeout={500}
								transitionEnter={true}
								transitionLeave={true}
							>
								{this.state.sysMessage}
							</ReactCSSTransitionGroup>
								
						</div>
					</div>
				</div>
					
	        </div>
		)
	}

}

export default FormReg;