import React, { useContext } from 'react';
import {ContextLk} from '../../context.js';
import {ContextApp} from '../../context.js';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import Close from '../../img/decor/close001.svg';
import Edit from '../../img/decor/edit001.svg';
import AddresMarker from '../../img/geo/markerPoligon1.svg';


export default function EventItem({event}){
	const {getUserEvents} = useContext(ContextLk);
	const {appLocationState} = useContext(ContextApp);

	let eventPos = event.pos;
	eventPos = eventPos.split(', ');
	eventPos = [Number(eventPos[0]), Number(eventPos[1])];

	function deleteEvent (eventToken) {
		const req1 = new URLSearchParams();
		req1.append('deleteEvent', 1);
		req1.append('token', eventToken);

	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    // .then(r => r.json())
	    .then(() => {
	    	getUserEvents()
	    })
	}

	return (
		<div className="listUnit001" >
			<div className="listUnitControlWr">
				<img src={AddresMarker} alt="marker" onClick={()=>appLocationState('map', 'showPoint', eventPos)} />
			</div>
            <div className="listUnitName">
              {event.point}
            </div>
            <div className="listUnitControlWr">
            	<img src={Edit} alt="Edit" onClick={()=>appLocationState('eventEdit', event.token, event.pos)} />
            	<img src={Close} alt="Delete" onClick={()=>deleteEvent(event.token)} />
            </div>
		</div>
	)
}