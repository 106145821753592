import React from 'react';
import {EventPlusBtn} from './base-elements';
import BaseHead001 from './base-head001';
import {ContextApp} from '../context.js'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Cookies from 'js-cookie';

import { YMaps, Map, Placemark } from 'react-yandex-maps';
import Marker03 from '../img/decor/marker03.png';
import MarkerDefBack1 from '../img/geo/markerDefBack1.svg';
import MarkerDef1 from '../img/geo/markerDef1.svg';
import GeoBtn from '../img/geo/geoBtn01.svg';
import MarkerRedDog from '../img/geo/markerDogRed01.svg';
import MarkerGreenDog from '../img/geo/markerDogGreen01.svg';
import MarkerRedCat from '../img/geo/markerCatRed01.svg';
import MarkerGreenCat from '../img/geo/markerCatGreen01.svg';

class MarkerList extends React.Component {

	constructor(props){
		super(props);
		this.state = {markerList: this.props.markerList};
		this.openEvent = this.openEvent.bind(this);
	}

	openEvent(val, param){
		this.props.appNav(val, param)
	}

	render(){
		// let position = [54.31493321810654, 48.40339122090147];
		console.log(this.state.markerList);
		return(
			// <Placemark defaultGeometry={position} />
			this.state.markerList.map((item, i) => {
				let position = String(item.pos);
				if(position){
					let previewMarker;
					if(item.category == 'Пропавшие собаки')
						previewMarker = MarkerRedDog;
					else if(item.category == 'Найденные собаки')
						previewMarker = MarkerGreenDog;
					else if(item.category == 'Пропавшие кошки')
						previewMarker = MarkerRedCat;
					else if(item.category == 'Найденные кошки')
						previewMarker = MarkerGreenCat;
					else
						previewMarker = MarkerDef1;

					position = position.split(', ');
					let lat = Number(position[0]);
					let lng = Number(position[1]);
					position = [lat, lng];
					console.log(item.category)

					// let popupCont = item.disc;
					// 		properties={{
					// 			balloonContentBody:
					// 				popupCont,
					// 		}}

					return(
						<Placemark 
							modules={['geoObject.addon.balloon']}
							geometry={position} 
							eventToken={item.token} 
							key={i}
							onClick={() => this.openEvent('eventFrame', item.token)}
							options={{
								iconLayout: 'default#image',
								iconImageHref: previewMarker,
								iconImageSize: [30, 40],
								iconImageOffset: [-20, -30],
							}}
						/>
					)
				}
			})
		)
	}
}

class map001 extends React.Component {

	constructor(props){
		super(props);
		this.appNav = this.appNav.bind(this);
	}

	state = {
		lat: null,
		lng: null,
		userPos: null,
		mapCenter: [54.314192, 48.403123],

		zoom: 16
	}

	appNav(val, param, location){
		this.props.appLocationState(val, param, location)
	}

	componentDidMount(){
		if(this.props.param == 'setAddres'){
			this.getAccerateUserCoords();
			this.setState({
            	mapCenter: this.props.location,
            })
        }
        else if(this.props.param == 'showPoint'){
        	this.getAllEventMarkers();
        	this.setState({
            	mapCenter: this.props.location,
            })
		}else{
			this.getAllEventMarkers();
			this.getAccerateUserCoords();
		}
	}

	componentWillUnmount() {
		clearInterval(this.userGeo);
	}

	getUserCoodrs(){
		/* карта и геокодинг */
        if (navigator.geolocation){

            /* установка карты и первичное определении позиции юзера */
            navigator.geolocation.getCurrentPosition(

                (position) => {
					let lt = position.coords.latitude;
                    let lng = position.coords.longitude;

                    console.log(lt + ', ' + lng);

                    this.setState({
                    	lat: lt, 
                    	lng: lng,
                    	mapCenter: [lt, lng]
                    })
                }
            )
        }
	}

	getAccerateUserCoords(){

		if (navigator.geolocation){
            /* установка карты и определении точной позиции позиции юзера */

            const watchId = navigator.geolocation.watchPosition(

                (position) => {
					let lt = position.coords.latitude;
                    let lng = position.coords.longitude;

                    console.log(lt + ', ' + lng);

                    this.setState({
                    	lat: lt, 
                    	lng: lng,
                    	userPos: [lt, lng],
                    	mapCenter: [lt, lng]
                    })
                },
                (error) => {
                	console.log('Ошибка: ' + error.code + ' ' + error.message);
                	alert('Включите геолокацию!');
                },
                // EnableHighAccuracy: true, maximumAge: 60000
            )

            setTimeout(() => {
			  navigator.geolocation.clearWatch(watchId)
			}, 4 * 1000)
            // navigator.geolocation.clearWatch(watchId);
        }

	}

	getAllEventMarkers(){
		const req1 = new URLSearchParams();
		req1.append('getAllEventsData', 1);

	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((request) => {
	    	// for(let set in request){
	    	// 	console.log( set.location )
	    	// }
			request.map((item, i) => {
				console.log(item)
			})

	    	this.setState({ markerList: request})
	    })
	    // .then(request => console.log(request))
	}

	// findLocation(){
	// 	let request = this.props.location;
	// 	fetch('https://geocode-maps.yandex.ru/1.x/?apikey=39760cf0-262c-484c-b9ba-6b8a31e8b251&format=json&geocode=' + request)
	// 	.then(r => r.json())
	// 	.then((result) => {
	// 		if(result['response'] && result['response']['GeoObjectCollection']['featureMember'][0]){
	// 			let point = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['Point']['pos'];
	// 			let description = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['description'] || 'не надено';
	// 			let name = result['response']['GeoObjectCollection']['featureMember'][0]['GeoObject']['name'] || 'не надено';
	// 			console.log(point);
	// 			console.log(description);
	// 			console.log(name);
				
	// 			let coords = point.split(' ');
	// 			let lat = coords[1];
	// 			let lng = coords[0];
	// 			this.setState({lat: lat, lng: lng})
	// 		}else{
	// 			console.log('не найдено');
	// 		}
	// 	})
	// }
	mapClick(e){
		if(this.props.param == 'setAddres'){
			var coords = e.get('coords');
			coords = coords[1]+', '+coords[0];
			// console.log(coords);
			// this.setState({ pos: coords });
			this.appNav('newEvent', 'setPoint', coords)
		}
	}
	newEvent(){
		var coords = this.state.lng + ', ' + this.state.lat;
		this.appNav('newEvent', 'setPoint', coords)
		// console.log(coords)
	}
	refreshGeo(){
		this.getAccerateUserCoords()
	}
	findGeoCicle(){
		this.userGeo = setInterval(
			() => this.getAccerateUserCoords(),
			5000
		);
	}

	// анимация_ожидания_геолокации
	waitGeo(){
		if(this.props.param != 'showPoint'){
			if(!this.state.lat || !this.state.lng){
				return(
					<div className="back001">
						<div className="waitGeoWr" onClick={this.refreshGeo.bind(this)}>
							<img src={MarkerDefBack1} alt="markerBack" className="waitGeoMarkerBack"/>
							<img src={MarkerDef1} alt="markerGeo" className="waitGeoMarker"/>
						</div>
					</div>
				)
			}else{
				return(
					<div className="geoBtnWr" onClick={this.refreshGeo.bind(this)}>
						<img src={GeoBtn} alt="geoBtn" />
					</div>
				)
			}
		}
	}

	// яндекс_карты
	render(){
		// let position = [54.314192, 48.403123];
		let position = [this.state.lat, this.state.lng];
		let userPos = this.state.userPos;
		let mapCenter = this.state.mapCenter;
		let zoom = this.state.zoom;

		let innerMapCont;
		if(this.props.param == 'setAddres'){
			innerMapCont = (<div></div>)
		}else{
			innerMapCont = (<MarkerList markerList={this.state.markerList} appNav={this.appNav.bind(this)}/>)
		}

		let plusButton
		if(Cookies.get('loggedUser')){
			plusButton = (
				<div className="plusBtn" onClick={this.newEvent.bind(this)}>
					<span>+</span>
				</div>
			)
		}else{
			plusButton = (<div></div>)
		}
		return(
			<YMaps
				className="mapWr"
				query={{apikey: '39760cf0-262c-484c-b9ba-6b8a31e8b251'}}
			>
				<BaseHead001 appNav={this.appNav.bind(this)} pageName="создать игру" />
				<Map 
					className="mapCont"
					state={{
						center: mapCenter, 
						zoom: zoom,
						controls: ['zoomControl', ]
					}}
					modules={['control.ZoomControl', ]}
					onClick={this.mapClick.bind(this)}
					usercoords={position}
				>
					{/*<Placemark defaultGeometry={position} />*/}
					{innerMapCont}
					<Placemark 
						geometry={userPos}
						replace={userPos}
					/>
					{plusButton}
					{this.waitGeo()}
				</Map>
			</YMaps>
		)
	}


}

export default map001;