import React from 'react';
import Cookies from 'js-cookie';
import {ContextLk} from '../context.js';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import BaseHead001 from './base-head001';
import {BaseActionBtn} from './base-elements';
import EventItem from './lk/eventItem.js';


class UserLK extends React.Component {

	constructor(props) {
		super(props);
		this.handleForm = this.handleForm.bind(this);
		this.getUserEvents = this.getUserEvents.bind(this);
		this.appNav = this.appNav.bind(this);
		this.state = {
			token: Date.now(),
			sysMessage: null
		}
	}

	appNav(val, param){
		this.props.appLocationState(val, param);
	}

	componentDidMount(){
		this.getUserData();
		this.getUserEvents()
	}

	getUserData(){
		let userToken = Cookies.get('loggedUser');
		const req1 = new URLSearchParams();
		req1.append('getUserData', 1);
		req1.append('userToken', userToken);

	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((eventData) => {
	    	this.setState({
    			user_idx: eventData.id,
    			login: eventData.login,
    			author: eventData.author,
    			email: eventData.email,
    			phone: eventData.phone
    		})
	    	// for(let param in eventData){
	    	// 	console.log(param)
	    	// 	let val = eventData[param];
	    	// 	this.setState({
	    	// 		[param]: val
	    	// 	})
	    	// }
	    })
	    // .then(this.getUserEvents())
	}

	getUserEvents(){
		let userToken = Cookies.get('loggedUser');
		const req1 = new URLSearchParams();
		console.log(this.state.login);
		req1.append('getUserEvents', 1);
		req1.append('userToken', userToken);

	    fetch('https://server.ifsmart.ru/helpmap.php',{
			method: "POST",
			body: req1,
			// headers: {'content-type': 'applycation/x-www-form-urlencoded; charset=utf-8'},
			// mode: 'cors'
	    })
	    .then(r => r.json())
	    .then((row) => {
	    	if(row != ''){
		 		this.setState({ eventsList: row});
	    	}
	    })
	}

	eventsList(){
		if(this.state.eventsList){
			return(
				<div className="baseFormWr">
					<div>Список событий:</div>
					<div className="wr-0 mt-3">
						{this.state.eventsList.map( (event, i) => {
							// console.log(event.point)
							return <EventItem event={event} key={i} />
						})}
					</div>
				</div>
			)
		}
		else{
			return(<div></div>)
		}
	}

	handleForm(event){
		let set = event.target.name;
		let val = event.target.value;
		this.setState({ [set]: val 	})
	}

	logOut(){
		Cookies.remove('loggedUser');
		this.appNav('map')
	}

	render(){
		return(
			<ContextLk.Provider value={{getUserEvents: this.getUserEvents}}>
				<div className="baseWrapper">
					<BaseHead001 appNav={this.appNav.bind(this)} pageName="РЕГИСТРАЦИЯ" />

					<div className="baseContWr">
						<div className="baseFormWr0">
							<div className="baseFormWr">
								<div>Логин: <span className="lkUserFormStr">{this.state.login}</span></div>
								<div>Имя: <span className="lkUserFormStr">{this.state.author}</span></div>
								<div>E-mail: <span className="lkUserFormStr">{this.state.email}</span></div>
								<div>Телефон: <span className="lkUserFormStr">{this.state.phone}</span></div>
							</div>
							<div className="baseFormWr">
								<div className="formActionBtnWr">
									<div className="baseActionBtn" onClick={this.logOut.bind(this)}>
										<span>ВЫЙТИ</span>
									</div>
								</div>
							</div>
							{this.eventsList()}
						</div>
					</div>
		        </div>
	        </ContextLk.Provider>
		)
	}

}

export default UserLK;